<template>
  <div>
    <Loader v-if="loading"></Loader>
    <CRow v-else>
      <CCol lg="12">
        <AbstractTable
          :fields="fields"
          :items="challenges"
          :actions="actions"
          title="Retos"
          :labelItemPerPage="'Retos por página:'"
          :addBtn="{ enable: $store.state.isAdmin, text: 'Añadir nuevo reto', event: 'addElement'}"
          @addElement="addChallenge"
          @showDeleteModal="handleModal"
          :noResults="'No se han encontrado retos'"
        />
        <DeleteModal :modalContent="modalContent" @delete="deleteChallengeData(challengeId)" :showModal="deleteModal" @closeModal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import "regenerator-runtime/runtime";
import AbstractTable from "@/components/Tables/AbstractTable.vue";
import DeleteModal from "@/components/Modals/DeleteModal.vue";
import Loader from "@/components/Loader.vue";
import { getChallengesData, deleteChallenge } from '@/services/challenges';
import { generateField } from '@/utils/utils';
import { getImage } from '@/services/images';

export default {
  name: "ChallengeTable",
  components: { AbstractTable, DeleteModal, Loader },
  data(){
    return{
      deleteModal: false,
      loading: true,
      challenge: {},
      challenges: [],
      challengeId: 0,
      challengeName: '',
      fields: [
        generateField("title", "Nombre del reto", {styleData: { textAlign: 'center'}}),
        generateField("description", "Descripción del reto"),
        generateField("image", "Imagen", {type: "image", styleData: { textAlign: 'center'}}),
        generateField("actions", 'Acciones', { show: this.$store.state.isAdmin, sorter: false, filter: false })
      ],
      actions: [
        {
          key: 'delete',
          label: 'Eliminar',
          isAdmin: !this.$store.state.isAdmin,
          event: 'showDeleteModal',
          classButton: 'btn btn-delete btn-sm btn-pill'
        },
      ],
      modalContent: {
            title: 'Eliminar reto',
            textBody: '',
            buttons: [
                {
                  textButton: 'Cerrar',
                  color: 'secondary',
                  event: 'closeModal'
                },
                {
                  textButton: 'Aceptar',
                  color: 'danger',
                  event: 'delete'
                }
            ]
      }
    }
  },
  created() {
    this.receiveData();
  },
  methods: {
    receiveData(){
      this.getChallenges();
    },
    /**
     * Función en la que se obtienen los retos y que también
     * llama el metodo receiveData para cargarlos.
     * 
     * @returns {object[]} Los retos obtenidos.
     */
    getChallenges() {
      getChallengesData()
        .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
        .then((result) => {
        this.challenges = result;
        this.challenges.forEach((challenge) => {
          getImage(challenge.image)
                .catch((error) => { this.$store.state.errorAlert = { status: true, msg: error.toString() }; this.loading = false; })
                .then((imageUrl ) => {
                   challenge.image = (challenge.image) ? imageUrl : null;
                   this.loading = false;
                });
          })
          this.loading = false;
      });
    },
    /**
     * Este método se ejecuta al pulsar Añadir nuevo reto, redirige a la vista del formulario para crear un reto
     */
    addChallenge() {
      this.$router.push(`/challenge/create/`);
    },
    /**
     * Método para controlar lo que se muestra en el Modal de Eliminar reto.
     */
    handleModal(item){
      this.challengeId = item.id;
      this.modalContent.textBody = `¿Está seguro de que desea eliminar el reto: <strong>"${item.title} </strong>"? Esta acción no se puede deshacer.`,
      this.deleteModal = true;
    },
    /**
     * Este metodo eliminará el reto, mandando por la URL el id
     * 
     * @param {number} id - El id del reto a eliminar
     */
     deleteChallengeData(id) {
      deleteChallenge(id).then(() => {
            this.getChallenges()
        })
        this.deleteModal = false;
     },
    /**
     * Función para cerrar el Modal.
     */
     closeModal(){
        this.deleteModal = false;
    }
  },
};
</script>